<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="orderData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list' }"
        >
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert>

    <b-row
      v-if="orderData"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12">
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="
                d-flex
                justify-content-between
                flex-md-row flex-column
                invoice-spacing
                mt-0
              "
            >
              <!-- Header: Left Content -->
              <div>
                <h3 class="text-primary invoice-logo">
                  PwC's Marketplace
                </h3>
                <p class="card-text mb-25">
                  {{ orderData.sellerAddress || "Level 10, Menara TH 1 Sentral,Jalan Rakyat, Kuala Lumpur Sentral" }}
                </p>
  
                <p class="card-text mb-0">
                  {{ orderData.sellerPostCode || "5067, Kuala Lumpur" }}  
                </p>
                <p class="card-text mb-0">
                  {{ orderData.sellerCountry || "Malaysia" }}   
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Order
                  <span class="invoice-number">#{{ orderData.id }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Date Issued:
                  </p>
                  <p class="invoice-date">
                    {{ formatDate(orderData.createdAt) }}
                  </p>
                </div>
                <div
                  v-if="false"
                  class="invoice-date-wrapper"
                >
                  <p class="invoice-date-title">
                    Due Date:
                  </p>
                  <p class="invoice-date">
                    {{ formatDate(orderData.updatedAt) }}
                  </p>
                </div>
                <div
                  class="invoice-date-wrapper"
                >
                  <p class="invoice-date-title">
                    Status:
                  </p>
                  <p class="invoice-date">
                    {{ mapStatus(orderData.status) }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2">
                  Invoice To:
                </h6>
                <h6 class="mb-25">
                  <strong>Customer name:</strong>{{ `${orderData.firstName} ${orderData.lastName}` }}
                </h6>
                <p class="card-text mb-25">
                  <!-- {{ orderData.client.company }} -->
                </p>
                <p class="card-text mb-25">
                  <strong>Address:</strong> {{ orderData.city }}
                  {{ orderData.country }}
                </p>
                <p class="card-text mb-25">
                  <strong>Phone:</strong> {{ orderData.mobile }}
                </p>
                <p class="card-text mb-0">
                  <strong>Email:</strong> {{ orderData.email }}
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                v-if="false"
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">
                    Payment Details:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Total Due:
                        </td>
                        <td>
                          <span class="font-weight-bold">{{
                            orderData.subTotal
                          }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Bank name:
                        </td>
                        <td>Viet Nam bank</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Country:
                        </td>
                        <td>{{ orderData.country }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          IBAN:
                        </td>
                        <td>1122</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          SWIFT code:
                        </td>
                        <td>1234</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="orderData.orderItems"
            :fields="['description', 'price', 'quantity', 'total']"
          >
            <template #cell(description)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.product.title }}
              </b-card-text>
            </template>
            <template #cell(price)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ regionPrice(orderData.region) + " " + data.item.price }}
              </b-card-text>
            </template>
            <template #cell(total)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{
                  regionPrice(orderData.region) +
                    " " +
                    data.item.price * data.item.quantity
                }}
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <!-- <span class="font-weight-bold">Salesperson:</span> -->
                  <!-- <span class="ml-75">Alfie Solomons</span> -->
                </b-card-text>
              </b-col>
              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Subtotal:
                    </p>
                    <p class="invoice-total-amount">
                      {{
                        regionPrice(orderData.region) + " " + orderData.subTotal
                      }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Discount:
                    </p>
                    <p class="invoice-total-amount">
                      {{
                        regionPrice(orderData.region) +
                          " " +
                          (orderData.itemDiscount + orderData.discount)
                      }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Tax:
                    </p>
                    <p class="invoice-total-amount">
                      {{ regionPrice(orderData.region) + " " + orderData.taxesConfig.taxLocal }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      {{
                        regionPrice(orderData.region) + " " + orderData.total
                      }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import { formatDate } from '@/utils/utils'
import { mapGetters } from 'vuex'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BAlert,
    BLink,
    Logo,
  },
  computed: {
    ...mapGetters('app', ['regionPriceOptions']),
    ...mapGetters('order', ['mapStatusOrders']),

  },
  setup() {
    const orderData = ref(null)
    const paymentDetails = ref({})

    // Invoice Description
    // ? Your real data will contain this information

    store
      .dispatch('order/fetchOrder', { id: router.currentRoute.params.id })
      .then(response => {
        orderData.value = response.body
        // paymentDetails.value = response.data.paymentDetails
      })
      .catch(error => {
        orderData.value = undefined
      })
    const fetchRegions = (ctx, callback) => {
      store
        .dispatch('regionConfig/fetchRegionConfig', {
          limit: perPage.value,
          page: currentPage.value,
        })
        .then(response => {
          const regionConfigs = response.body.data
          totalRegionConfig.value = response.body.total
          callback(regionConfigs)
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching regions list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const printInvoice = () => {
      window.print()
    }

    return {
      orderData,
      paymentDetails,
      printInvoice,
      formatDate,
    }
  },
  methods: {
    mapStatus(id) {
      return this.mapStatusOrders.find(e => e.value === id).label
    },
    regionPrice(region) {
      return this.regionPriceOptions.find(x => x.value === region).label
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
